import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ProductHighlightDrPepperCherryZero from "../components/ProductHighlightDrPepperCherryZero";
import ProductLinkListHorizonal from "../components/ProductLinkListHorizonal";

const Content = () => {
  const title = "Dr Pepper Cherry Zero";
  const description = "Dr Pepper Zero Sugar is the same smooth blend of cherries and sugar that you love in Diet Cherry.";
  return (
    <Layout>
      <Seo title={title + " Sugar"} description={description} />
      <Container>
        <Row>
          <Col>
            <h1>{title + " Sugar"}</h1>
            <p>{description}</p>
          </Col>
        </Row>

        <Row>
          <Col sm={8}>
            <h2>Nutrition Facts</h2>
            <p>Size 12 fl oz</p>
            <table class="nutrition-table">
              <thead>
                <tr>
                  <th>Per Serving</th>
                  <th>% DAILY VALUE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>Calories: </b>0
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <b>Total Fat: </b>0 g
                  </td>
                  <td>0%</td>
                </tr>
                <tr>
                  <td>
                    <b>Sodium: </b>60 mg
                  </td>
                  <td>3%</td>
                </tr>
                <tr>
                  <td>
                    <b>Total Carbohydrates: </b>0 g
                  </td>
                  <td>0%</td>
                </tr>
                <tr>
                  <td>
                    <b>Protein: </b>0 g
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <p className="mt-4 mb-3">*Based on a 2000 calorie diet</p>
          </Col>

          <Col sm={8}>
            <h2 className="mt-4 mb-3">{title} Ingredients</h2>
            <ul>
              <li>Carbonated Water</li>
              <li>Caramel Color</li>
              <li>Natural and Artificial Flavors</li>
              <li>Aspartame</li>
              <li>Acesulfame Potassium</li>
              <li>Sodium Benzoate (preservative)</li>
              <li>Citric Acid</li>
              <li>Phosphoric Acid</li>
              <li>Caffeine</li>
              <li>Malic Acid</li>
              <li>Sodium Phosphate</li>
              <li>Red 40</li>
            </ul>
          </Col>

          <Col sm={12} className="mb-5">
            <h2 className="mt-4 mb-3">Available Sizes</h2>
            <ProductHighlightDrPepperCherryZero />
          </Col>
        </Row>
        <Row>
          <Col>
            <ProductLinkListHorizonal title="Products" />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Content;
